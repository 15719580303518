<template>
  <div>
    <div class="filter-container">
      <el-form inline>
        <el-form-item>
          <el-input v-model="query.keyword" placeholder="请输入高能经纪人名称" clearable />
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.recharge" placeholder="请选择是否充值成为高能经纪人" clearable style="width: 100%">
            <el-option v-for="(value, key) in type" :key="key" :value="key" :label="value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          <el-button type="danger" icon="el-icon-refresh" @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
        prop="uid"
        label="UID"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="nickname"
        label="高能经纪人"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="cellphone"
        label="手机号"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ status[row.status] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="签约时间"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="expireTime"
        label="到期时间"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="createTime"
        label="到期自动降级"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          <el-button type="danger" size="small" @click="demotion(row.id)">降级</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="(val) => {
          page = val
          getList()
        }"
      />
    </div>
  </div>
</template>

<script>
import list from '@/mixins/list'
import * as api from '@/api/heigAgent'
export default {
  mixins: [list],
  data() {
    return {
      api,
      status: {
        0: '未启用',
        1: '启用',
        2: '拉黑',
        3: '冻结',
        4: '未拉黑'
      },
      type: ['否', '是']
    }
  },
  methods: {
    demotion(id) {
      this.$confirm('此操作将使该用户变为普通用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.demotion(id).then(() => {
          this.$message({
            message: '降级成功',
            type: 'success'
          })
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消降级'
        })
      })
    }
  }
}
</script>
