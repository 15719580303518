import request from '@/utils/axios'

/** 获取高能经纪人列表 */
export function getList(params) {
  return request({
    url: '/broker',
    method: 'get',
    params
  })
}

/** 降级 */
export function demotion(id) {
  return request({
    url: `/broker/demotion?id=${id}`,
    method: 'put'
  })
}
